import { useEffect, useState } from 'react'
import type { NextPage, GetStaticProps } from 'next'
import Feed from '@ui/components/feed'
import Head from '@ui/components/head'
import MainLayout from '@ui/components/main-layout'
import { usePostsFeed, viralService, Post } from '@api/viral'
import flatten from 'lodash/flatten'

const LIMIT = 25

const LIST_PARAMS = { source: 'oldrow', limit: LIMIT }

type HomeProps = {
  posts: Post[]
}

const Home: NextPage<HomeProps> = (props) => {
  const [hasMore, setHasMore] = useState(true)
  const { data, ...rest } = usePostsFeed(LIST_PARAMS, {
    fallbackData: [props.posts]
  })

  useEffect(() => {
    if (data?.length && data[data.length - 1].length < LIMIT) {
      setHasMore(false)
    }
  }, [data?.length, data, rest.size])

  function handleNext(size: number) {
    if (!data?.length || !hasMore) return
    return rest.setSize(size)
  }

  const items = flatten(data)
  return (
    <MainLayout title='Old Row Viral'>
      <Head />
      <Feed {...rest} items={items} fetchMore={handleNext} hasMore={hasMore} />
    </MainLayout>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const posts = await viralService.list(LIST_PARAMS)
  return {
    props: { posts },
    revalidate: 1
  }
}

export default Home
