import { useState, useEffect } from 'react'
import classNames from 'classnames'
import styles from '@styles/feed.module.css'
import type { Post as IPost } from '@api/types'
import Post from '@ui/components/post'
import { usePageVisibility } from '@ui/hooks/page-visibility'
import InfiniteScroll from '@ui/components/infinite-scroll'
import LoadingContainer from '@ui/components/loading-container'
import SubmissionCTA from '@ui/components/sidebar-cta'

interface IFeed {
  items: IPost[] | undefined
  size: number
  isLoading: boolean
  fetchMore: any
  hasMore: boolean
}

function Feed({ items, ...props }: IFeed) {
  const [playingVideoId, setPlayingVideoId] = useState<null | string>(null)
  const isPageVisible = usePageVisibility()
  const loader = () => <LoadingContainer isLoading={true} />

  useEffect(() => {
    // stop playing if page is not visible
    if (isPageVisible === false && playingVideoId) {
      setPlayingVideoId(null)
    }
  }, [isPageVisible, playingVideoId])

  const togglePlaying = (id: string) => {
    setPlayingVideoId((prevPlayingVideoId: string | null) => {
      return prevPlayingVideoId === id ? null : id
    })
  }
  const handleNext = async function getNext() {
    return props.fetchMore(props.size + 1)
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <aside className={classNames(styles.col, styles.col_sidebar, styles.col_left)}>
          <div className={styles.sticky_container}>
            <div className={styles.sticky}>
              <SubmissionCTA />
            </div>
          </div>
        </aside>
        <main className={classNames(styles.col, styles.col__main)}>
          <InfiniteScroll loader={loader} next={handleNext} hasMore={props.hasMore}>
            {!!items?.length &&
              items.map((post) => (
                <Post
                  key={post.id}
                  post={post}
                  playing={playingVideoId === post.id}
                  togglePlaying={() => togglePlaying(post.id)}
                  startPlaying={() => setPlayingVideoId(post.id)}
                  stopPlaying={() => setPlayingVideoId(null)}
                />
              ))}
          </InfiniteScroll>
        </main>
      </div>
    </div>
  )
}

export default Feed
