import { useState, useRef, useEffect } from 'react'
import { isServer } from '@lib/utils'

export function usePageVisibility(callback?: () => void) {
  const savedCallback = useRef<(args: any) => void>()
  const [isVisible, setIsVisible] = useState(getIsDocumentVisible())

  const onVisibilityChange = (args: any) => {
    if (getIsDocumentVisible()) {
      setIsVisible(true)
      savedCallback?.current && savedCallback.current(args)
    } else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the event listener.
  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()
    window.addEventListener(visibilityChange as string, onVisibilityChange, false)
    return () => {
      window.removeEventListener(visibilityChange as string, onVisibilityChange)
    }
  })

  return isVisible
}

function getBrowserVisibilityProp() {
  const doc = document as any
  if (!isServer && typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange'
  } else if (typeof doc.msHidden !== 'undefined') {
    return 'msvisibilitychange'
  } else if (typeof doc.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange'
  }
}

function getBrowserDocumentHiddenProp() {
  const doc = document as any
  if (typeof document.hidden !== 'undefined') {
    return 'hidden'
  } else if (typeof doc.msHidden !== 'undefined') {
    return 'msHidden'
  } else if (typeof doc.webkitHidden !== 'undefined') {
    return 'webkitHidden'
  }
}

function getIsDocumentVisible() {
  if (isServer) return false
  return (!document as any)[getBrowserDocumentHiddenProp() as string]
}