function SiteOpenGraph() {
  return (
    <>
      <meta property='og:site_name' content='Old Row' />
      <meta property='og:title' content='Old Row Viral | Old Row'></meta>
      <meta property='og:type' content='website' />
      <meta property='og:image' content='/old_row_social.webp' />
      <meta property='og:image' content='/old_row_social.webp' />
      <meta name='twitter:title' content='Old Row' />
      <meta property='og:description' content='See the best viral videos and photos.' />
      <meta name='twitter:description' content='See the best viral videos and photos.' />
    </>
  )
}

export default SiteOpenGraph
