import styles from '@styles/sidebar-cta.module.css'

function SidebarCTA() {
  return (
    <div className={styles.card}>
      <h3 className={styles.h3}>Shop Old Row</h3>
      <img
        className={styles.image}
        src='https://chumley.barstoolsports.com/union/2022/06/15/275857602_548901759705399_3840268836744732788_n.11fe92ec.jpg?crop=4:3,smart'
        alt='Shop Old Row'
      />
      <a href='https://shop.oldrow.net' className={styles.button}>
        Shop Now
      </a>
    </div>
  )
}

export default SidebarCTA
