import React, { useEffect, useState, ReactNode } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import styles from '@styles/infinite-scroll.module.css'

interface IInfiniteScroll {
  next: () => Promise<any>
  hasMore: boolean
  offset?: number
  children?: any
  loader?: () => ReactNode
  disabled?: boolean
}

type fetchCallback = () => void | Promise<any>

const InfiniteScroll = ({
  next,
  hasMore = false,
  offset = 800,
  loader,
  children = null,
  disabled = false
}: IInfiniteScroll) => {
  const [visible, setVisible] = useState(false)
  const [fetch, setFetch] = useState<boolean | fetchCallback>(false)

  useEffect(() => {
    if (!visible || !hasMore || disabled || fetch) return

    // Execute next batch
    const op = next().finally(() => {
      // Clear current fetch after a delay to give time for re-render
      setTimeout(() => setFetch(false), 250)
    })

    // Set fetch op
    setFetch(op as any)
  }, [visible, hasMore, disabled, fetch, next])

  return (
    <div>
      {children}
      <VisibilitySensor
        partialVisibility
        intervalCheck={true}
        intervalDelay={200}
        scrollCheck={true}
        scrollThrottle={200}
        resizeCheck={true}
        resizeThrottle={200}
        offset={{ bottom: -offset, top: -offset }}
        onChange={(newValue: any) => setVisible(newValue)}
      >
        <div className={styles.infinite_scroll_sensor}>{fetch && loader?.()}</div>
      </VisibilitySensor>
    </div>
  )
}

export default InfiniteScroll
