import NextHead from 'next/head'
import { Post } from '@api/types'
import PostOpenGraph from '@ui/components/post-open-graph'
import SiteOpenGraph from '@ui/components/site-open-graph'

interface IHead {
  post?: Post
}

function Head({ post }: IHead) {
  return <NextHead>{post ? <PostOpenGraph post={post} /> : <SiteOpenGraph />}</NextHead>
}

export default Head
